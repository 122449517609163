<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_search_group">
        <el-form
          :inline="true"
          size="min"
          :model="searchForm"
          ref="searchForm"
          @keyup.enter.native="getUsersNow()"
        >
          <el-row :gutter="24">
            <el-col :md="8">
              <el-form-item label="员工账号:">
                <el-input
                  size="small"
                  v-model.trim="searchForm.user_name"
                  clearable
                  placeholder="请填写账号"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="员工姓名:">
                <el-input
                  size="small"
                  v-model.trim="searchForm.stff_name"
                  clearable
                  placeholder="请填写姓名"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="所属公司:">
                <el-input
                  size="small"
                  v-model.trim="searchForm.cptt_name"
                  clearable
                  placeholder="请填写所属公司"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :md="8">
              <el-form-item label="部门名称:">
                <el-select
                  v-model="searchForm.dept_id"
                  placeholder="请选择部门"
                  clearable
                  @visible-change="getDeptV1($event)"
                  size="small"
                >
                  <el-option
                    v-for="item in deptGroupOpt"
                    :key="item.dept_id"
                    :label="item.dept_name"
                    :value="item.dept_id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="小组名称:">
                <el-select
                  v-model="searchForm.dept_team_id"
                  placeholder="请选择小组"
                  clearable
                  @visible-change="getDeptTeams"
                  size="small"
                >
                  <el-option
                    v-for="item in deptTeamsGroupOpt"
                    :key="item.dept_team_id"
                    :label="item.dept_team_name"
                    :value="item.dept_team_id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :md="10">
              <el-form-item label="创建时间:">
                <div class="block">
                  <el-date-picker
                    size="small"
                    v-model="searchForm.timeValue"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
            </el-col>
            <!--         <el-col :md="4">-->
            <!--           <el-form-item>-->
            <!--             <el-button size="small" type="primary" icon="el-icon-search" @click="getUsersNow()">查询</el-button>-->
            <!--           </el-form-item>-->
            <!--         </el-col>-->
          </el-row>
          <el-row :gutter="24">
            <el-col :md="14">
              <el-form-item label="注销时间:">
                <div class="block">
                  <el-date-picker
                    size="small"
                    v-model="searchForm.timeValue1"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button
                  size="small"
                  type="primary"
                  icon="el-icon-search"
                  @click="getUsersNow()"
                  class="vg_ml_16"
                  >查询</el-button
                >
                <el-button
                  type="info"
                  size="small"
                  class="vd_export"
                  @click="buttonRefresh()"
                  ><i class="el-icon-refresh-right"></i> 刷新</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="vd_button_group vg_mtb_16">
        <el-button
          type="primary"
          size="small"
          @click="addRow()"
          :disabled="!btn.add"
          ><i class="el-icon-plus"></i> 新增</el-button
        >
        <el-button
          type="danger"
          size="small"
          @click="doDelete()"
          :disabled="!btn.delete"
          ><i class="el-icon-delete"></i> 注销</el-button
        >
        <el-button type="success" size="small" @click="restore()"
          ><i class="el-icon-setting"></i> 恢复</el-button
        >
      </div>
      <el-row>
        <el-col :md="24">
          <el-table
            class="vg_pointer"
            ref="multiTable"
            :data="tableData"
            @selection-change="handleSelectionChange"
            @row-dblclick="dbClickJp"
            border
            v-loading="loadingFlag"
          >
            <el-table-column type="selection" width="48" align="center" />
            <el-table-column
              label="员工账号"
              prop="user_name"
              :show-overflow-tooltip="true"
            />
            <el-table-column
              label="员工姓名"
              prop="stff_name"
              :show-overflow-tooltip="true"
            />
            <el-table-column
              label="部门名称"
              prop="dept_name"
              :show-overflow-tooltip="true"
            />
            <el-table-column
              label="小组名称"
              prop="dept_team_name"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.dept_team_name">{{
                  scope.row.dept_team_name
                }}</span>
                <span v-else class="vg_notava">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="账号状态" prop="user_status" align="center">
              <template slot-scope="scope">
                <el-tag type="info" v-if="scope.row.user_status == 0"
                  >已注销</el-tag
                >
                <el-tag v-if="scope.row.user_status == 1">未使用</el-tag>
                <el-tag type="success" v-if="scope.row.user_status == 2"
                  >使用中</el-tag
                >
                <el-tag type="warning" v-if="scope.row.user_status == 3"
                  >卡使用</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column
              label="所属公司"
              prop="cptt_name"
              :show-overflow-tooltip="true"
            />
            <el-table-column
              label="创建时间"
              prop="create_time"
              :formatter="formatDate"
            />
            <el-table-column
              label="注销时间"
              prop="delete_time"
              :formatter="formatDateDel"
            />
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <pubPagination
            :totalPage="totalPage"
            @changePageSearch="changePageSearch"
            ref="pubPagination"
          ></pubPagination>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { get, post } from "@api/request";
import { deptAPI } from "@api/modules/department";
import { userAPI } from "@api/modules/user";
import pubPagination from "@/components/common/pubPagination";
export default {
  name: "UserList",
  components: {
    pubPagination,
  },
  data() {
    return {
      searchForm: {
        user_name: "",
        stff_name: "",
        dept_id: null,
        dept_team_id: null,
        timeValue: {
          startTime: null,
          endTime: null,
        },
        timeValue1: {
          startTime: null,
          endTime: null,
        },
      },
      cpttGroupOpt: [],
      deptGroupOpt: [],
      deptTeamsGroupOpt: [],
      currentPage: 1,
      totalPage: 0,
      tableData: [],
      multiSelection: [],
      btn: {},
      loadingFlag: true,
    };
  },
  created() {
    this.initData();
  },
  watch: {
    $route(to, from) {
      if (from.path === "/user_add" || from.path === "/user_edit") {
        this.initData();
      }
    },
  },
  methods: {
    initData() {
      this.loadingFlag = true;
      this.getUsersList();
    },
    getUsersList() {
      const timeNewVal = this.helper.getTime(this.searchForm.timeValue);
      const timeNewVal1 = this.helper.getTime(this.searchForm.timeValue1);
      const searchBody = {
        user_name: this.searchForm.user_name,
        stff_name: this.searchForm.stff_name,
        dept_id: this.searchForm.dept_id,
        dept_team_id: this.searchForm.dept_team_id,
        page_no: this.currentPage,
        cptt_name: this.searchForm.cptt_name,
        start_time: timeNewVal.startTime,
        end_time: timeNewVal.endTime,
        start_time2: timeNewVal1.startTime,
        end_time2: timeNewVal1.endTime,
      };
      get(userAPI.getUsers, searchBody)
        .then((res) => {
          if (res.data.code === 0) {
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.total;
            this.btn = res.data.data.btn;
            setTimeout(() => {
              this.loadingFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = "error";
            this.$message({ message: mg, type: tp });
          }
        })
        .catch((res) => {
          let mg = res.data.msg;
          let tp = "error";
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取deptId
    getDeptV1(flag) {
      if (flag === true && this.deptGroupOpt.length === 0) {
        get(deptAPI.getAllDeptsV1)
          .then((res) => {
            if (res.data.code === 0) {
              this.deptGroupOpt = res.data.data;
            } else {
              let mg = res.data.msg;
              let tp = "error";
              this.$message({ message: mg, type: tp });
            }
          })
          .catch((res) => {
            let mg = res.data.msg;
            let tp = "error";
            this.$message({ message: mg, type: tp });
          });
      }
    },
    // 获取deptTeamId
    getDeptTeams(flag) {
      if (flag === true && this.deptTeamsGroupOpt.length === 0) {
        get(deptAPI.getAllDeptTeams)
          .then((res) => {
            if (res.data.code === 0) {
              this.deptTeamsGroupOpt = res.data.data;
            } else {
              let mg = res.data.msg;
              let tp = "error";
              this.$message({ message: mg, type: tp });
            }
          })
          .catch((res) => {
            let mg = res.data.msg;
            let tp = "error";
            this.$message({ message: mg, type: tp });
          });
      }
    },
    // 刷新
    buttonRefresh() {
      this.$refs.pubPagination.currentPage = 1;
      this.searchForm = {
        user_name: "",
        stff_name: "",
        dept_id: null,
        dept_team_id: null,
        timeValue: {
          startTime: null,
          endTime: null,
        },
        timeValue1: {
          startTime: null,
          endTime: null,
        },
      };
      this.currentPage = 1;
      this.initData();
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time);
    },
    formatDateDel(row) {
      if (!this.helper.toStringDate(row.delete_time)) {
        return "暂未注销";
      } else {
        return this.helper.toStringDate(row.delete_time);
      }
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    // 查询
    getUsersNow() {
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.initData();
    },
    // 分页查询
    changePageSearch(val) {
      this.currentPage = val;
      this.initData();
    },
    // 双击编辑
    dbClickJp(row) {
      if (row.user_status === 0) {
        this.$message({ type: "info", message: "该账号已注销" });
      } else {
        this.jump("/user_edit", {
          perm_id: this.$route.query.perm_id,
          form_id: row.user_id,
        });
      }
    },
    // 新增
    addRow() {
      this.jump("/user_add", { perm_id: this.$route.query.perm_id });
    },
    //删除提示
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: "warning", message: "请至少选择一条数据！" });
      } else {
        this.mBox();
      }
    },
    mBox() {
      this.$confirm("确定注销？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.delRow();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消注销",
          });
          this.$refs.multiTable.clearSelection();
        });
    },
    // 删除
    delRow() {
      const ids = [];
      this.multiSelection.map((item) => {
        if (item.user_status != 0) {
          ids.push(item.user_id);
        }
      });
      if (ids.length == 0) {
        return this.$message.warning("至少选择一个未注销的账号");
      }
      post(userAPI.deleteUserByIds, { user_id_list: ids })
        .then((res) => {
          if (res.data.code === 0) {
            let mg = res.data.msg;
            let tp = "success";
            this.$message({ message: mg, type: tp });
            if (ids.length === this.tableData.length) {
              if (this.currentPage > 1) {
                this.currentPage = this.currentPage - 1;
                this.$refs.pubPagination.currentPage = this.currentPage;
              }
            }
            this.initData();
          } else {
            let mg = res.data.msg;
            let tp = "error";
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch((res) => {
          let mg = res.data.msg;
          let tp = "error";
          this.$message({ message: mg, type: tp });
          this.getDeptsNow();
        });
    },
    restore() {
      this.$confirm("确定恢复？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const ids = [];
          this.multiSelection.map((item) => {
            if (item.user_status == 0) {
              ids.push(item.user_id);
            }
          });
          if (ids.length == 0) {
            return this.$message.warning("至少选择一个已注销的账号");
          }

          //接口
          post(userAPI.cancel_delete, { user_id_list: ids })
            .then((res) => {
              if (res.data.code == 0) {
                this.initData();
                return;
              }
              this.$message.error(res.data.msg);
            })
            .catch((res) => {
              this.$message.error(res.data.msg);
            });
        })
        .catch((res) => {
          this.$message.warning("已取消恢复");
        });
    },
  },
};
</script>

<style scoped lang="scss">
</style>